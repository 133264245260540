<template>
  <svg
    viewBox="0 0 8192.14 6922.55"
    class="cct-icon"
    v-bind="$attrs"
    version="1.1"
    id="svg2"
    sodipodi:docname="cctCanabis.svg"
    xml:space="preserve"
    inkscape:version="1.3.2 (091e20e, 2023-11-25)"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"><defs
                                             id="defs2" /><sodipodi:namedview
                                               id="namedview2"
                                               bordercolor="#000000"
                                               borderopacity="0.25"
                                               inkscape:showpageshadow="2"
                                               inkscape:pageopacity="0.0"
                                               inkscape:pagecheckerboard="0"
                                               inkscape:deskcolor="#d1d1d1"
                                               inkscape:zoom="0.028433218"
                                               inkscape:cx="-2303.6436"
                                               inkscape:cy="4783.1378"
                                               inkscape:window-width="1472"
                                               inkscape:window-height="833"
                                               inkscape:window-x="0"
                                               inkscape:window-y="37"
                                               inkscape:window-maximized="0"
                                               inkscape:current-layer="svg2" />
    <path
      d="m 6164.9099,6918.6701 c -62.84,6.43 -5521.07995,4.29 -5574.63995,-2.14 -292.81,-37.14 -512.760004,-230.67 -574.890004,-505.62 -12.8599996,-60 -19.2899996,-161.39 -12.8599996,-226.38 5.71,-67.85 1089.0799536,-5342.53003 1114.0699536,-5426.10003 78.56,-264.95 285,-509.91 542.76,-644.17 92.84,-47.840004 150.68,-70.000004 249.23,-95.000004 l 75,-19.28000018 h 5648.91 l 67.84,18.57000018 c 242.81,67.13 407.78,230.670004 471.34,467.050004 19.28,71.42 26.42,214.25 15,287.8 -19.29,125.69 -1101.94,5361.12003 -1119.08,5411.82003 -27.85,85 -69.27,169.26 -122.12,248.53 -60.7,92.12 -207.1,239.95 -298.51,302.08 -148.54,100 -320.65,165.68 -482.05,182.82 z m -4902.63,-919.82 2317.41,1.43 c 1327.6,0 2328.84,-2.15 2343.84,-5.72 48.56,-11.42 115,-50 155.68,-91.41 77.84,-77.84 32.85,119.27 560.61,-2450.24 392.78,-1909.63 475.62,-2323.84 472.77,-2354.55 -7.15,-71.41 -45,-127.83003 -107.84,-161.40003 l -29.28,-15.71 h -4677.67 l -48.57,23.57 c -69.27,34.28 -127.83,91.41003 -157.82,154.25003 -22.86,46.42 -65,245.67 -500.62,2364.55 -261.38,1272.61 -474.91,2325.27 -474.91,2339.55 0,70.7 45,142.12 110,176.4 z"
      id="path1"
      inkscape:label="path1"
    />
    <path
      id="XMLID_277_"
      d="m 5846.0705,3785.8055 c 0,0 -483.9567,-32.5036 -963.0241,169.9405 89.8182,-104.9874 177.7571,-221.8027 257.3179,-351.1626 396.018,-644.703 431.5862,-1342.8397 431.5862,-1342.8397 0,0 -606.594,347.2895 -1002.7571,991.9856 -58.2391,94.7148 -107.7298,190.4928 -151.4527,285.1387 26.6974,-158.298 44.2311,-328.1693 44.2311,-506.0721 0,-826.1024 -365.9629,-1495.8629 -365.9629,-1495.8629 0,0 -365.8165,669.7605 -365.8165,1495.8782 0,177.8953 17.549,347.7743 44.2156,506.0183 -43.7387,-94.6611 -93.3521,-190.3778 -151.4674,-285.0926 -396.1181,-644.6959 -1002.7501,-991.9854 -1002.7501,-991.9854 0,0 35.599,698.1366 431.609,1342.8395 79.538,129.3599 167.4846,246.1754 257.3178,351.1628 -479.2137,-202.4519 -963.0468,-169.9483 -963.0468,-169.9483 0,0 292.5391,364.4536 773.262,569.1001 183.4085,78.0596 367.5723,121.3896 525.4081,145.392 -74.5478,5.5139 -153.9854,16.4173 -234.8862,36.2692 -349.9308,85.559 -592.9799,299.6159 -592.9799,299.6159 0,0 324.2268,75.2179 674.2575,-10.4032 226.9091,-55.5737 407.5688,-164.3811 507.4049,-234.4629 l -83.5648,668.5595 c -3.8968,31.7639 4.8897,63.6984 24.3101,87.8158 19.4203,24.0637 47.3342,37.909 76.8042,37.909 h 160.0992 c 29.4617,0 57.3762,-13.8453 76.8123,-37.909 19.4202,-24.1179 28.1833,-56.0519 24.3021,-87.8158 l -83.5654,-668.5056 c 99.8515,70.0973 280.4964,178.8429 507.2663,234.4095 350.0388,85.6129 674.2575,10.4027 674.2575,10.4027 0,0 -243.0561,-214.0569 -592.9485,-299.6159 -80.9473,-19.8516 -160.3846,-30.7553 -234.9249,-36.2692 157.8899,-24.0024 342.0535,-67.3324 525.462,-145.392 480.707,-204.6465 773.2235,-569.1001 773.2235,-569.1001 z"
    />
  </svg>
</template>


<script>
export default {
  name: 'cct-canabis'
}
</script>

<style lang="css">
.cct-icon {
  fill: currentColor;
}
</style>
