<template>
  <svg viewBox="0 0 8192.14 6922.55" class="cct-icon" v-bind="$attrs">
    <path d="M6164.91,6918.67c-62.84,6.43-5521.08,4.29-5574.64-2.14-292.81-37.14-512.76-230.67-574.89-505.62-12.86-60-19.29-161.39-12.86-226.38C8.23,6116.68,1091.6,842,1116.59,758.43c78.56-264.95,285-509.91,542.76-644.17,92.84-47.84,150.68-70,249.23-95l75-19.28H7632.49l67.84,18.57c242.81,67.13,407.78,230.67,471.34,467.05,19.28,71.42,26.42,214.25,15,287.8-19.29,125.69-1101.94,5361.12-1119.08,5411.82-27.85,85-69.27,169.26-122.12,248.53-60.7,92.12-207.1,239.95-298.51,302.08-148.54,100-320.65,165.68-482.05,182.82ZM1262.28,5998.85l2317.41,1.43c1327.6,0,2328.84-2.15,2343.84-5.72,48.56-11.42,115-50,155.68-91.41,77.84-77.84,32.85,119.27,560.61-2450.24,392.78-1909.63,475.62-2323.84,472.77-2354.55-7.15-71.41-45-127.83-107.84-161.4l-29.28-15.71H2297.8l-48.57,23.57c-69.27,34.28-127.83,91.41-157.82,154.25-22.86,46.42-65,245.67-500.62,2364.55-261.38,1272.61-474.91,2325.27-474.91,2339.55,0,70.7,45,142.12,110,176.4Z"/>
    <path d="M3959.72,4966.19c-172.83-16.43-375.64-73.56-529.19-149.26-469.91-231.38-784.84-684.15-839.83-1206.19-11.43-108.55-5-301.37,14.28-407.78,97.12-554.9,491.33-1009.81,1023.37-1181.92,349.22-112.83,723.44-96.41,1058.37,45.71,526.33,222.81,882.69,726.29,917.68,1295.46,16.43,272.09-33.56,522-152.82,766.28-219.25,447.06-649.88,758.43-1144.07,828.42C4210.39,4970.47,4047.56,4974.76,3959.72,4966.19Z"/>
  </svg>
</template>


<script>
export default {
  name: 'cct-icon'
}
</script>

<style lang="css">
.cct-icon {
  fill: currentColor;
}
</style>
